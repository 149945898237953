/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Ampli } from '../ampli';
import { FlattyPlugin } from './middlewares/flatty.middleware';
import { ZarazPlugin } from './middlewares/zaraz.middleware';
import type { LoadOptionsWithEnvironment } from '../ampli';

interface TrackerOptions {
  amplitudeOptions: LoadOptionsWithEnvironment;
  sourceProject?: string;
  sourcePlatform?: string;
}

class TrackerClass extends Ampli {
  currentUser?: { id?: string; email?: string };
  public sourceProject?: string;

  setCurrentUser(id?: string, email?: string) {
    this.currentUser = { id, email };
  }

  setSourceProject(sourceProject?: string) {
    this.sourceProject = sourceProject;
  }
}

let Tracker: TrackerClass;

export type TrackerType = typeof Tracker;

export const setupTracker = (trackerOptions: TrackerOptions) => {
  const isSSR = typeof window === 'undefined';

  if (isSSR) {
    throw new Error('Tracker cannot be started in SSR mode');
  }

  Tracker = new TrackerClass();

  const { environment } = trackerOptions.amplitudeOptions;

  const trackerLogger = (message: unknown) => {
    if (environment !== 'production') {
      // eslint-disable-next-line no-console
      console.log('Tracker:', message);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userSession = (window as any).__userSession as
    | {
        id: string;
        email: string;
        subscription: {
          level: string;
          daysRemaining: number;
          activeRecurrence: string;
        };
        product: {
          id: number;
          key: string;
          title: string;
        };
      }
    | undefined;

  // Monkey patch `track` to ensure identify is called before tracking.
  // @ts-ignore
  Tracker.track = function (event, options) {
    // @ts-ignore
    if (!this.isInitializedAndEnabled()) {
      trackerLogger('Tracker not initialized and enabled');
      return { promise: Promise.resolve() };
    }

    if (userSession) {
      const { id, email, subscription, product } = userSession;
      this.client.setUserId(id);
      this.identify(id, {
        email,
        subscription: subscription.level,
        'days remaining': subscription.daysRemaining,
        recurrence: subscription.activeRecurrence,
        segment: product.title
      });

      trackerLogger(`Tracking user ${id} with email ${email}`);
    } else {
      trackerLogger(`Tracking anonymous user`);
    }

    trackerLogger(event);
    // @ts-ignore
    return this.amplitude.track(
      {
        ...event,
        event_properties: {
          ...event.event_properties,
          'source platform': trackerOptions.sourcePlatform,
          source_project: trackerOptions.sourceProject
        }
      },
      undefined,
      options
    );
  };

  Tracker.setSourceProject(trackerOptions.sourceProject);

  Tracker.load(trackerOptions.amplitudeOptions);

  const flattyPlugin = new FlattyPlugin();
  Tracker.client.add(flattyPlugin);

  const zarazPlugin = new ZarazPlugin();
  Tracker.client.add(zarazPlugin);
};

export { Tracker };
